// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-convert-js": () => import("./../../../src/pages/convert.js" /* webpackChunkName: "component---src-pages-convert-js" */),
  "component---src-pages-docs-js": () => import("./../../../src/pages/docs.js" /* webpackChunkName: "component---src-pages-docs-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-support-statically-js": () => import("./../../../src/pages/support-statically.js" /* webpackChunkName: "component---src-pages-support-statically-js" */),
  "component---src-pages-wordpress-js": () => import("./../../../src/pages/wordpress.js" /* webpackChunkName: "component---src-pages-wordpress-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-docs-js": () => import("./../../../src/templates/docs.js" /* webpackChunkName: "component---src-templates-docs-js" */),
  "component---src-templates-policies-js": () => import("./../../../src/templates/policies.js" /* webpackChunkName: "component---src-templates-policies-js" */)
}

